import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Select, Button, Card, notification } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import userStore from "../../../store/user";
import CustomAvatar from "../../avatar";
import imageStore from "../../../store/avatar";
import './style.scss';

const { Option } = Select;

const ProfileCard = observer(() => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        let birthDate = null;

        if (values.birthYear && values.birthMonth && values.birthDay) {
            birthDate = `${values.birthYear}-${values.birthMonth?.toString().padStart(2, '0')}-${values.birthDay?.toString().padStart(2, '0')}`;
        }
        const mockData = {
            username: values.username || "string",
            firstName: values.firstName || "string",
            lastName: values.lastName || "string",
            middleName: values.middleName || "string",
            birthDate: birthDate,
            avatar: userStore.user?.user?.avatar || imageStore.imageUrl?.url,
            tgUsername: values.tgUsername || "string"
        };

        try {
            await userStore.edit(mockData);
            await userStore.fetchUser();
            notification.success({
                message: t('Profile Updated'),
                description: t('Your profile has been updated successfully.')
            });
        } catch (error) {
            console.error('Failed to update user:', error);
            notification.error({
                message: t('Update Failed'),
                description: t('There was an error updating your profile. Please try again later.')
            });
        }
    };

    useEffect(() => {
        if (userStore.user) {
            const user = userStore.user?.user;
            const birthDate = user?.birthDate ? dayjs(user.birthDate) : null;
            form.setFieldsValue({
                ...user,
                birthDay: birthDate ? birthDate.date() : undefined,
                birthMonth: birthDate ? birthDate.month() + 1 : undefined,
                birthYear: birthDate ? birthDate.year() : undefined
            });
        }
    }, [userStore.user, form]);

    const currentYear = dayjs().year();
    const years = Array.from({ length: currentYear - 1969 }, (_, i) => currentYear - i);

    return (
        <Card className="content">
            <CustomAvatar
                borderRadius
                initialImageUrl={userStore.user?.user?.avatarUrl}
                onImageUpload={(newImageUrl) => form.setFieldsValue({ avatar: newImageUrl })}
            />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    ...userStore.user?.user,
                    birthDay: userStore.user?.user?.birthDate ? dayjs(userStore.user.user.birthDate).date() : undefined,
                    birthMonth: userStore.user?.user?.birthDate ? dayjs(userStore.user.user.birthDate).month() + 1 : undefined,
                    birthYear: userStore.user?.user?.birthDate ? dayjs(userStore.user.user.birthDate).year() : undefined
                }}
                onFinish={onFinish}
                className="form-container"
            >
                <Form.Item label={t("Username")} name="username">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("First Name")} name="firstName">
                    <Input />
                </Form.Item>
                <Form.Item label={t("Last Name")} name="lastName">
                    <Input />
                </Form.Item>
                <Form.Item label={t("Middle Name")} name="middleName">
                    <Input />
                </Form.Item>
                <Form.Item label={t("Email")} name="email">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("Phone")} name="phone">
                    <Input disabled addonBefore="+7" />
                </Form.Item>
                <Form.Item label={t("Birth Date")}>
                    <Input.Group compact>
                        <Form.Item name="birthDay" noStyle>
                            <Select placeholder={t("Day")} style={{ width: '30%' }}>
                                {Array.from({ length: 31 }, (_, i) => (
                                    <Option key={i + 1} value={i + 1}>{(i + 1).toString().padStart(2, '0')}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="birthMonth" noStyle>
                            <Select placeholder={t("Month")} style={{ width: '30%' }}>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <Option key={i + 1} value={i + 1}>{dayjs().month(i).format('MM')}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="birthYear" noStyle>
                            <Select placeholder={t("Year")} style={{ width: '40%' }}>
                                {years.map(year => (
                                    <Option key={year} value={year}>{year}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item label={t("Telegram Username")} name="tgUsername">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="btn" htmlType="submit">{t("Save")}</Button>
                </Form.Item>
            </Form>
        </Card>
    );
});

export default ProfileCard;
