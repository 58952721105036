import React from 'react';
import { observer } from 'mobx-react';
import OtherUserProfileCard from "../../components/profile/profile-details-card";
import ProfileTournamentDetails from "../../components/profile/profile-details-tournament";
import ProfileTournamentUpcomingDetails from "../../components/profile/profile-details-upcomming";
import ProfileTeamDetails from "../../components/profile/profile-details-team";
import './style.scss';

const ProfileDetails = observer(() => {
    return (
        <div className="container profile">
            <div className="card-container">
                <OtherUserProfileCard />
                <div className="flower">
                    <ProfileTeamDetails />
                    <ProfileTournamentDetails />
                    <ProfileTournamentUpcomingDetails />
                </div>
            </div>
        </div>
    );
});

export default ProfileDetails;
