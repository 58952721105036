import React, { useState, useEffect } from "react";
import { Button, Drawer, Menu, Dropdown } from "antd";
import { UserOutlined, GlobalOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import userStore from "../../store/user";
import Logo from "../logo";
import ModalConfirm from "../modals/modal-confirm";
import authStore from "../../store/authStore";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import "./style.scss";

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isAuthenticated = !!userStore.user;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    dayjs.locale(i18n.language === 'kz' ? 'ru' : i18n.language);
  }, [i18n.language]);

  const handleTeamNavigation = () => {
    if (userStore.user?.team?.id) {
      navigate("/team");
    } else {
      navigate("/team/create");
    }
  };

  const handleLogout = () => {
    authStore.clearToken();
    window.location.href = `${window.location.origin}/login`;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleConfirmLogout = () => {
    setIsModalVisible(false);
    handleLogout();
  };

  const handleCancelLogout = () => {
    setIsModalVisible(false);
  };

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    setIsDrawerVisible(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dayjs.locale(lng === 'kz' ? 'ru' : lng);
  };

  const languageMenuItems = [
    {
      key: 'en',
      label: 'English',
      onClick: () => changeLanguage('en')
    },
    {
      key: 'ru',
      label: 'Русский',
      onClick: () => changeLanguage('ru')
    },
    {
      key: 'kz',
      label: 'Қазақша',
      onClick: () => changeLanguage('kz')
    }
  ];

  const languageMenu = <Menu items={languageMenuItems} />;

  return (
      <div className="header">
        <div className="content">
          <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            <Logo />
          </div>
          <div className="menu">
            <div
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                className="title hover"
            >
              {t('Home')}
            </div>
            <div
                onClick={() => navigate("/tournaments")}
                style={{ cursor: "pointer" }}
                className="title hover"
            >
              {t('Tournaments')}
            </div>
            <div
                onClick={handleTeamNavigation}
                style={{ cursor: "pointer" }}
                className="title hover"
            >
              {t('Team')}
            </div>
          </div>
          <div className="auth-actions">
            {isAuthenticated ? (
                <>
                  <Button
                      icon={<UserOutlined />}
                      className="hover"
                      onClick={() => navigate("/profile")}
                  >
                    {t('Profile')}
                  </Button>
                  <Button className="hover" onClick={showModal}>
                    {t('Logout')}
                  </Button>
                </>
            ) : (
                <>
                  <Button
                      icon={<UserOutlined />}
                      className="hover"
                      onClick={() => navigate("/login")}
                  >
                    {t('Login')}
                  </Button>
                  <Button
                      icon={<UserOutlined />}
                      className="hover"
                      onClick={() => navigate("/register")}
                  >
                    {t('Register')}
                  </Button>
                </>
            )}
            <Dropdown overlay={languageMenu} trigger={['click']}>
              <Button icon={<GlobalOutlined />} className="hover">
                {t('Language')}
              </Button>
            </Dropdown>
          </div>
          <Button
              icon={<MenuOutlined />}
              className="menu-button"
              onClick={toggleDrawer}
          />
        </div>

        <Drawer
            title="Меню"
            placement="right"
            closable={true}
            onClose={toggleDrawer}
            open={isDrawerVisible}
        >
          <div onClick={() => handleMenuClick("/")} className="drawer-item">
            {t('Home')}
          </div>
          <div onClick={() => handleMenuClick("/tournaments")} className="drawer-item">
            {t('Tournaments')}
          </div>
          <div onClick={() => handleMenuClick("/team")} className="drawer-item">
            {t('Team')}
          </div>
          {isAuthenticated ? (
              <>
                <div onClick={() => handleMenuClick("/profile")} className="drawer-item">
                  {t('Profile')}
                </div>
                <div onClick={showModal} className="drawer-item">
                  {t('Logout')}
                </div>
              </>
          ) : (
              <>
                <div onClick={() => handleMenuClick("/login")} className="drawer-item">
                  {t('Login')}
                </div>
                <div onClick={() => handleMenuClick("/register")} className="drawer-item">
                  {t('Register')}
                </div>
              </>
          )}
        </Drawer>

        <ModalConfirm
            visible={isModalVisible}
            onConfirm={handleConfirmLogout}
            onCancel={handleCancelLogout}
            title={t('ConfirmLogoutTitle')}
            content={t('ConfirmLogoutContent')}
        />
      </div>
  );
};

export default observer(Header);
