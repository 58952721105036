import { makeAutoObservable, runInAction } from "mobx";
import {
    addPlayerTournament,
    createTournaments,
    deletePlayerTournament,
    getTournament,
    searchTournaments,
    editTournament,
} from "../api";

class TournamentStore {
    tournaments = [];
    currentTournament = null;
    loading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(value) {
        this.loading = value;
    }

    setError(value) {
        this.error = value;
    }

    setTournaments(tournaments) {
        this.tournaments = tournaments;
    }

    setCurrentTournament(tournament) {
        this.currentTournament = tournament;
    }


    async fetchTournamentsByName(query) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await searchTournaments(query);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setTournaments(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to search tournaments');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async getTournamentProfile(tournamentId) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await getTournament(tournamentId);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setCurrentTournament(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to fetch tournament');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async addPlayerToTournament(tournamentId) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await addPlayerTournament(tournamentId);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setCurrentTournament(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to add player to tournament');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchCreateTournament(data) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await createTournaments(data);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setCurrentTournament(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to create tournament');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async editTournament(data, id) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await editTournament(data, id);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    const index = this.tournaments.findIndex(tournament => tournament.id === id);
                    if (index !== -1) {
                        this.tournaments[index] = response.data;
                    }
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to edit tournament');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async removePlayerFromTournament(tournamentId, playerId) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await deletePlayerTournament(tournamentId, playerId);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setCurrentTournament(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to remove player from tournament');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const tournamentStore = new TournamentStore();
export default tournamentStore;
