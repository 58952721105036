import React, { useState, useEffect } from 'react';
import { Upload, Button, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import imageStore from '../../store/avatar';
import "./style.scss";

const CustomAvatar = observer(({ initialImageUrl, onImageUpload, shape = 'circle' }) => {
    const { t } = useTranslation();
    const [previewImage, setPreviewImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(initialImageUrl);

    useEffect(() => {
        setImageUrl(initialImageUrl);
    }, [initialImageUrl]);

    const handleUpload = async (options) => {
        const { onSuccess, onError, file } = options;
        setPreviewImage(URL.createObjectURL(file));
        try {
            const formData = new FormData();
            formData.append('file', file);
            await imageStore.createImg(formData);
            if (imageStore.error) {
                onError(imageStore.error);
            } else {
                const newImageUrl = imageStore.imageUrl;
                setImageUrl(newImageUrl);
                onSuccess(newImageUrl, file);
                if (onImageUpload) {
                    onImageUpload(newImageUrl);
                }
            }
        } catch (error) {
            onError(error);
        }
    };

    const uploadProps = {
        customRequest: handleUpload,
        showUploadList: false,
    };

    return (
        <div className="avatar-container">
            {previewImage || imageUrl ? (
                <Avatar size={128} src={previewImage || imageUrl} shape={shape} />
            ) : (
                <Avatar size={128} icon={<UploadOutlined />} shape={shape} />
            )}
            <Upload {...uploadProps}>
                <Button className="btn_small" loading={imageStore.loading}>{t('Upload Image')}</Button>
            </Upload>
        </div>
    );
});

export default CustomAvatar;
