import React, { useState, useEffect } from 'react';
import { Modal, AutoComplete, Input, Button, notification } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import teamStore from '../../../store/team';

const SearchUserModal = ({ visible, onCancel }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    const handleSearch = debounce(async (query) => {
        if (query) {
            const results = await teamStore.searchUsers(query);
            setOptions(results.map(user => ({ value: user.username, label: user.name })));
        } else {
            setOptions([]);
        }
    }, 300);

    useEffect(() => {
        if (teamStore.username) {
            handleSearch(teamStore.username);
        } else {
            setOptions([]);
        }
    }, [teamStore.username]);

    const handleAddPlayer = async () => {
        const newPlayer = {
            teamId: teamStore.team.teamInfo.id,
            username: teamStore.username,
        };
        try {
            await teamStore.addNewTeam(newPlayer);
            teamStore.setUsername(''); // Очистка поля ввода
            setOptions([]); // Очистка результатов поиска
            notification.success({
                message: t('Success'),
                description: t('Player added successfully'),
            });
            onCancel();
            await teamStore.fetchTeamById(); // Обновление данных команды после добавления нового игрока
        } catch (error) {
            notification.error({
                message: t('Error'),
                description: error.message || t('Failed to add new player'),
            });
        }
    };

    const handleChange = (value) => {
        teamStore.setUsername(value);  // Сохраняем результат поиска в MobX store
    };

    return (
        <Modal
            open={visible}
            title={t("Search Players")}
            onCancel={() => {
                teamStore.setUsername(''); // Очистка поля ввода при закрытии модального окна
                setOptions([]); // Очистка результатов поиска при закрытии модального окна
                onCancel();
            }}
            footer={null}
        >
            <AutoComplete
                options={options}
                style={{ width: '100%' }}
                onSelect={(value) => {
                    teamStore.setUsername(value);
                }}
                onSearch={handleChange}
                value={teamStore.username}
            >
                <Input placeholder={t("Search for players")} />
            </AutoComplete>
            <Button
                onClick={handleAddPlayer}
                style={{ marginTop: 16 }}
                className="btn_small btn-center"
            >
                {t("Add Player")}
            </Button>
        </Modal>
    );
};

export default observer(SearchUserModal);
