import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Avatar,
  Card,
  Divider,
  Skeleton,
  Tabs,
  Button,
  message,
  Table,
} from "antd";
import {
  CalendarOutlined,
  TeamOutlined,
  TrophyOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import tournamentStore from "../../store/tournaments";
import userStore from "../../store/user"; // Добавим userStore для получения данных текущего пользователя
import CreateTournamentModal from "../../components/modals/modal-create-tournaments";
import { checkCapitan, checkTournament } from "../../api";
import useUserPermissions from "../../helpers/hooks";
import "./style.scss";

const { TabPane } = Tabs;

const TournamentDetails = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const { isAdmin, isCaptain } = useUserPermissions();
  const navigate = useNavigate();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      await checkCapitan();
      const registrationStatus = await checkTournament({ tournamentId: id });
      setIsRegistered(registrationStatus);
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  };

  useEffect(() => {
    tournamentStore.getTournamentProfile(id);
  }, [id]);

  const formatDate = (startDate, endDate) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : null;
    if (end && start.month() === end.month()) {
      return `${start.date()} – ${end.date()} ${start.format("MMMM")}`;
    }
    return end
        ? `${start.format("D MMMM")} – ${end.format("D MMMM")}`
        : start.format("D MMMM");
  };

  const handleJoinTournament = async () => {
    try {
      await tournamentStore.addPlayerToTournament({ tournamentId: id });
      await tournamentStore.getTournamentProfile(id);
      message.success(t("Successfully joined the tournament!"));
    } catch (error) {
      message.error(t("Failed to join the tournament."));
    }
  };

  const handleViewDetails = (participant) => {
    const currentUser = userStore.user;
    if (isFormat1x1) {
      if (participant.username === currentUser.user.username) {
        navigate(`/profile`);
      } else {
        navigate(`/profile/${participant.username}`);
      }
    } else {
      if (participant.teamId === currentUser.team?.id) {
        navigate(`/team`);
      } else {
        navigate(`/team/${participant.teamId}`);
      }
    }
  };

  if (tournamentStore.loading) {
    return (
        <Card bordered={false}>
          <Skeleton active paragraph={{ rows: 4 }} />
        </Card>
    );
  }

  if (tournamentStore.error) {
    return (
        <div className="container">
          <Card title={t("Error")}>
            <p>{tournamentStore.error.message}</p>
          </Card>
        </div>
    );
  }

  const tournament = tournamentStore.currentTournament;
  const isFormat1x1 = tournament?.format === "1x1";

  const columns = [
    {
      title: t("Name"),
      dataIndex: isFormat1x1 ? "username" : "teamFullName",
      key: "name",
    },
    {
      title: t("Action"),
      key: "action",
      render: (text, record) => (
          <div className="action-content">
            <Button className="btn_small" onClick={() => handleViewDetails(record)}>
              {t("Details")}
            </Button>
          </div>
      ),
    },
  ];

  const data = tournament?.participants?.map((participant) => ({
    key: participant.id,
    id: participant.id,
    username: participant.username,
    teamFullName: participant.teamFullName,
    teamId: participant.teamId,
  }));


  return (
      <div className="container tournament-details">
        {tournament ? (
            <>
              <Card bordered={false}>
                <div className="content">
                  <Avatar
                      size={128}
                      src={tournament?.logoUrl}
                      alt="Tournament Logo"
                      shape="square"
                  />
                  <div className="war">
                    <div className="name">{tournament?.fullName}</div>
                    <div className="title">{tournament?.disciplineName}</div>
                    <div className="info ">
                      <div className="text">
                        <TeamOutlined /> {t("Capacity")}: {tournament?.capacity}
                      </div>
                      <div className="text">
                        <TrophyOutlined /> {t("Format")}: {tournament?.format}
                      </div>
                      <div className="text">
                        <CalendarOutlined />{" "}
                        {formatDate(tournament?.startDate, tournament?.endDate)}
                      </div>
                    </div>
                    {isAdmin && (
                        <Button
                            className="btn_small btn-center"
                            icon={<EditOutlined />}
                            onClick={() => setIsModalVisible(true)}
                        >
                          {t("Edit")}
                        </Button>
                    )}

                    {!isAdmin && !isFormat1x1 && isCaptain && !isRegistered && (
                        <Button type="primary" onClick={handleJoinTournament}>
                          {t("Join")}
                        </Button>
                    )}
                    {!isAdmin && isFormat1x1 && !isRegistered?.data && (
                        <Button type="primary" className="btn_small btn-center" onClick={handleJoinTournament}>
                          {t("Join")}
                        </Button>
                    )}
                  </div>
                </div>
              </Card>

              <div className="flower">
                <div className="war-robot war">
                  <Card bordered={false} className="tournament-details-card">
                    <div>
                      <div className="flex alignC gap-10">
                        <CalendarOutlined className="title" />
                        <div className="war">
                          <div className="title">{t("Event Dates")}:</div>
                          <div className="text">
                            {" "}
                            {formatDate(tournament?.startDate, tournament?.endDate)}
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="text">
                        {t("Tournament status")}:{" "}
                        {tournament?.isActive ? (
                            <CheckCircleOutlined style={{ color: "green" }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: "red" }} />
                        )}
                      </div>
                    </div>
                  </Card>
                </div>

                <Tabs defaultActiveKey="1" className="games-tabs">
                  <TabPane tab={t("Overview")} key="1">
                    <div style={{ whiteSpace: "pre-wrap" }} className="text">
                      {tournament?.description}
                    </div>
                  </TabPane>
                  <TabPane tab={t("Teams")} key="2">
                    <Table columns={columns} dataSource={data} />
                  </TabPane>
                  <TabPane tab={t("Matches")} key="3">
                    <p className="text">{t("Matches content goes here...")}</p>
                  </TabPane>
                </Tabs>
              </div>
            </>
        ) : (
            <Card title={t("No tournament found")}>
              <p>{t("The tournament with the provided ID could not be found.")}</p>
            </Card>
        )}
        <CreateTournamentModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            tournament={tournament}
        />
      </div>
  );
});

export default TournamentDetails;
