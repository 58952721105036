import React, { useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Spin } from "antd";
import routing from "./routing";
import userStore from "./store/user";
import authStore from "./store/authStore";
import Header from "./components/header";

function App() {
    const location = useLocation();

    const unprotectedRoutes = ["/", "/login", "/register"];
    const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

    useEffect(() => {
        if (authStore.token) {
            userStore.fetchUser();
        }
    }, [authStore.token]);

    if (authStore.loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin tip="Loading authentication..." indicator={antIcon} />
            </div>
        );
    }

    if (!authStore.token && !unprotectedRoutes.includes(location.pathname)) {
        return <Navigate to="/login" />;
    }

    if (userStore.isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin tip="Loading user data..." indicator={antIcon} />
            </div>
        );
    }

    if (userStore.error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Alert
                    message="Error"
                    description={userStore.error.message}
                    type="error"
                    showIcon
                />
            </div>
        );
    }

    return (
        <main className="app">
            <Header />
            <Routes>
                {Object.keys(routing).map((key) => {
                    const route = routing[key];
                    if (route.role === "all" || unprotectedRoutes.includes(route.path)) {
                        return (
                            <Route key={key} path={route.path} element={route.element} />
                        );
                    }
                    return null;
                })}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </main>
    );
}

export default observer(App);
