import React from 'react';
import NewsFeed from "../../components/news";
import "./style.scss";

const Main = () => {

    return (
        <div className="container">
            <NewsFeed />
        </div>
    );
};

export default Main;
