import React from 'react';
import { Card, List } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import userStore from "../../../store/user";
import './style.scss';

const ProfileTournamentUpcoming = observer(() => {
    const { t } = useTranslation();
    const user = userStore.user;

    if (!user || !user.upcomingTournament) {
        return (
            <Card className="content tournament-card">
                <h2 className="name">{t('Upcoming Tournament')}</h2>
                <p className="text">{t('No upcoming tournaments.')}</p>
            </Card>
        );
    }

    const upcomingTournament = user.upcomingTournament;

    return (
        <Card className="content tournament-card">
            <h2 className="name">{t('Upcoming Tournament')}</h2>
            <List
                itemLayout="horizontal"
                dataSource={[upcomingTournament]}
                renderItem={tournament => (
                    <List.Item>
                        <List.Item.Meta
                            title={<span className="title">{tournament.tournamentFullName}</span>}
                            description={
                                <span className="text">
                                    {`${tournament.startDate} - ${tournament.endDate} | ${tournament.disciplineName}`}
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
});

export default ProfileTournamentUpcoming;
