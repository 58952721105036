import React, { useState } from "react";
import { Button, Input, Form, Spin, notification } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import loginImg from "../../../assets/img/login.jpeg";
import authStore from "../../../store/authStore";
import Logo from "../../../components/logo";
import "./style.scss";

const Login = observer(() => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const encodedData = btoa(`${username}:${password}`);
      await authStore.fetchLogin({ credentials: encodedData });
      navigate('/');
    } catch (error) {
      notification.error({
        message: t('Login Error'),
        description: t('There was an error during login. Please try again.'),
      });
      console.error("Error during login", error);
    }
  };

  return (
      <div className="container login">
        <div className="login-box">
          <div className="form-section">
            <Logo />
            <Form form={form} onFinish={handleLogin} layout="vertical" requiredMark={false} autoComplete="off">
              <Form.Item
                  name="username"
                  label={t('Username')}
                  rules={[{ required: true, message: t('Please input your username!') }]}
              >
                <Input
                    placeholder={t('Username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="new-username" // Используем 'new-username' для отключения автозаполнения
                />
              </Form.Item>
              <Form.Item
                  name="password"
                  label={t('Password')}
                  rules={[{ required: true, message: t('Please input your password!') }]}
              >
                <Input.Password
                    placeholder={t('Password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password" // Используем 'new-password' для отключения автозаполнения
                />
              </Form.Item>
              <Form.Item>
                <Button className="btn_small" htmlType="submit" block disabled={authStore.loading}>
                  {authStore.loading ? <Spin /> : t('Login')}
                </Button>
              </Form.Item>
            </Form>
            <Button className="btn-cancel" onClick={() => navigate("/register")} block>
              {t('Register')}
            </Button>
          </div>
          <img src={loginImg} alt={t("Login")} className="img" />
        </div>
      </div>
  );
});

export default Login;
