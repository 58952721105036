import { makeAutoObservable, runInAction } from "mobx";
import {
    addTeam,
    createTeam,
    deleteTeamMember,
    editTeam,
    getTeam,
    search,
    searchTeam
} from "../api";
import userStore from "./user";

class TeamStore {
    team = null;
    teamDetails = null;
    loading = false;
    error = null;
    username = ""; // Новая переменная

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(value) {
        this.loading = value;
    }

    setError(value) {
        this.error = value;
    }

    setTeam(team) {
        this.team = team;
    }

    setTeamDetails(id) {
        this.teamDetails = id;
    }


    setUsername(username) {
        this.username = username; // Метод для установки username
    }

    async createTeam(teamData) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await createTeam(teamData);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setTeam(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to create team');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async editTeam(data) {
        this.setLoading(true);
        this.setError(null);
        try {
            const teamId = userStore.user.team?.id;
            console.log("teamId", teamId)
            const response = await editTeam(data, teamId);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setTeam(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to edit team');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async addNewTeam(data) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await addTeam(data);
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to add team member');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async searchUsers(query) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await search(query);
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to search users');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchTeam(query) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await getTeam(query);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setTeamDetails(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to fetch team');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchTeamById() {
        this.setLoading(true);
        this.setError(null);
        try {
            const teamId = userStore.user.team?.id;
            const response = await getTeam(teamId);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setTeam(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to fetch team by ID');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async delete(data) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await deleteTeamMember(data);
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to delete team member');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const teamStore = new TeamStore();
export default teamStore;
