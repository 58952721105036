import React, { useEffect, useState } from "react";
import { Input, Card, Row, Col, Button, Spin, Skeleton } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import {
  CalendarOutlined,
  TeamOutlined,
  TrophyOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import GamesBar from "../../components/games";
import tournamentStore from "../../store/tournaments";
import disciplineStore from "../../store/games";
import CreateTournamentModal from "../../components/modals/modal-create-tournaments";
import useUserPermissions from "../../helpers/hooks";
import "./style.scss";

const Tournament = observer(() => {
  const { t } = useTranslation();
  const { isAdmin } = useUserPermissions(); // Use the custom hook
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    tournamentStore.fetchTournamentsByName("");
  }, []);

  const handleSearch = debounce(async (value) => {
    setLoading(true);
    setQuery(value);
    await tournamentStore.fetchTournamentsByName(value);
    setLoading(false);
  }, 300);

  const handleDetails = (id) => {
    navigate(`/tournaments/detail/${id}`);
  };

  const formatDate = (startDate, endDate) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : null;
    if (end && start.month() === end.month()) {
      return `${start.date()} - ${end.date()} ${start.format("MMMM")}`;
    }
    return end
        ? `${start.format("D MMMM")} - ${end.format("D MMMM")}`
        : start.format("D MMMM");
  };

  const filteredTournaments = tournamentStore.tournaments.filter(
      (tournament) =>
          disciplineStore.currentDiscipline === "all" ||
          tournament.disciplineId === disciplineStore.currentDiscipline,
  );

  return (
      <div className="container tournament">
        <GamesBar />
        <Input
            placeholder={t("Search tournaments")}
            size="large"
            className="tournament-input"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: "20px" }}
        />
        {loading ? (
            <Card bordered={false} className="tournament-input">
              <Skeleton active paragraph={{ rows: 4 }} />
            </Card>
        ) : (
            <Row gutter={[16, 16]}>
              {isAdmin && (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Card
                        className="create-tournament-card-add"
                        onClick={() => setIsModalVisible(true)}
                    >
                      <PlusOutlined className="name" />
                    </Card>
                  </Col>
              )}
              {filteredTournaments.map((tournament) => (
                  <Col key={tournament.id} xs={24} sm={12} md={8} lg={6}>
                    <Card
                        className="create-tournament-card"
                        title={<span className="title">{tournament.fullName}</span>}
                        bordered={false}
                    >
                      <div className="flex flex-column gap-10">
                        <div className="flex alignC gap-10">
                          <div className="text flex gap-5">
                            <TeamOutlined /> {tournament.capacity}
                          </div>
                          <div className="text flex gap-5">
                            <TrophyOutlined /> {tournament.format}
                          </div>
                          <div className="text flex gap-5">
                            <CalendarOutlined />
                            {formatDate(tournament.startDate, tournament.endDate)}
                          </div>
                        </div>
                        <div className="text flex alignC gap-5">
                          {t("Tournament status")}:
                          {tournament?.isActive ? (
                              <CheckCircleOutlined style={{ color: "green" }} />
                          ) : (
                              <CloseCircleOutlined style={{ color: "red" }} />
                          )}
                        </div>
                        {tournament.isActive && (
                            <Button
                                type="primary"
                                className="btn_small"
                                onClick={() => handleDetails(tournament.id)}
                            >
                              {t("View Details")}
                            </Button>
                        )}
                      </div>
                    </Card>
                  </Col>
              ))}
            </Row>
        )}
        <CreateTournamentModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
        />
      </div>
  );
});

export default Tournament;
