import { makeAutoObservable, runInAction } from "mobx";
import { checkCapitan, editUser, getUser, getUserProfile } from "../api";

class UserStore {
    user = null;
    profileDetails = null;
    isLoading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUser(user) {
        this.user = user;
    }

    setProfileDetails(profileDetails) {
        this.profileDetails = profileDetails;
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setError(error) {
        this.error = error;
    }

    async fetchUser() {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await getUser();
            runInAction(() => {
                this.setUser(response.data);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchCapitan() {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await checkCapitan();
            return response.data;
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchUserByUsername(username) {
        console.log('fetchUserByUsername called with username:', username);
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await getUserProfile(username);
            runInAction(() => {
                console.log('User profile fetched successfully:', response.data);
                this.setProfileDetails(response.data);
            });
        } catch (error) {
            console.error('Error fetching user profile:', error);
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async edit(userData) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await editUser(userData);
            runInAction(() => {
                this.setUser(response.data);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const userStore = new UserStore();
export default userStore;
