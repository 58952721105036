import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tabs, Avatar, Button, Modal, Form, Input, message } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import disciplineStore from "../../store/games";
import CustomAvatar from "../avatar";
import useUserPermissions from "../../helpers/hooks";
import "./style.scss";

const { TabPane } = Tabs;

const GamesBar = observer(() => {
  const { t } = useTranslation();
  const { isAdmin } = useUserPermissions(); // Use the custom hook
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [originalLogo, setOriginalLogo] = useState("");

  useEffect(() => {
    disciplineStore.fetchDisciplines();
  }, []);

  const handleTabChange = (key) => {
    disciplineStore.setCurrentDiscipline(key);
  };

  const showAddModal = () => {
    setIsEditMode(false);
    form.resetFields();
    setAvatarUrl("");
    setOriginalLogo("");
    setIsModalVisible(true);
  };

  const showEditModal = (discipline) => {
    setIsEditMode(true);
    form.setFieldsValue(discipline);
    setAvatarUrl(discipline.logoUrl);
    setOriginalLogo(discipline.logo);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinish = async (values) => {
    const logo = avatarUrl && avatarUrl.url ? avatarUrl.url : originalLogo;
    const updatedValues = {
      ...values,
      logo,
      isActive: true,
    };

    try {
      if (isEditMode) {
        await disciplineStore.editDiscipline(updatedValues, values.id);
      } else {
        await disciplineStore.createDiscipline(updatedValues);
      }
      disciplineStore.fetchDisciplines();
      setIsModalVisible(false);
      message.success(
          isEditMode
              ? t("Discipline updated successfully")
              : t("Discipline created successfully"),
      );
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await disciplineStore.deleteDisciplines(id);
      disciplineStore.fetchDisciplines();
      setIsModalVisible(false);
      message.success(t("Discipline deleted successfully"));
    } catch (error) {
      message.error(t("Failed to delete discipline"));
    }
  };

  if (disciplineStore.loading) {
    return <div>{t('Loading...')}</div>;
  }

  if (disciplineStore.error) {
    return <div>{t('Error')}: {disciplineStore.error.message}</div>;
  }

  return (
      <div className="games-tab">
        <div className="flex gap-10 alignC">
          <div className="name-tab">{t('Game Catalog')}</div>
          {isAdmin && (
              <Button
                  type="primary"
                  className="btn_small"
                  icon={<PlusOutlined />}
                  onClick={showAddModal}
              >
                {t('Add')}
              </Button>
          )}
        </div>
        <Tabs
            defaultActiveKey="all"
            className="games-tabs"
            tabBarGutter={10}
            onChange={handleTabChange}
        >
          <TabPane tab={<div className="discipline">{t('All')}</div>} key="all">
            {/* Содержимое вкладки "All" */}
          </TabPane>
          {disciplineStore.disciplines.map((discipline) => (
              <TabPane
                  tab={
                    <div className="discipline">
                      <Avatar
                          src={discipline.logoUrl}
                          alt={discipline.name}
                          className="game-icon"
                          shape="square"
                      />
                      <div>{discipline.name}</div>
                      {isAdmin && (
                          <EditOutlined
                              onClick={() => showEditModal(discipline)}
                              className="edit-icon"
                          />
                      )}
                    </div>
                  }
                  key={discipline.id}
              >
                {/* Содержимое вкладки */}
              </TabPane>
          ))}
        </Tabs>

        <Modal
            title={isEditMode ? t("Edit Discipline") : t("Add Discipline")}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
          <Form
              form={form}
              layout="vertical"
              onFinish={(values) =>
                  handleFinish({ ...values, id: form.getFieldValue("id") })
              }
              requiredMark={false}
          >
            {isEditMode && (
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
            )}
            <Form.Item
                name="name"
                label={t("Name")}
                rules={[
                  { required: true, message: t("Please enter the name") },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label={t("Logo")}
                rules={[
                  { required: true, message: t("Please select an image") },
                ]}
            >
              <CustomAvatar
                  shape="square"
                  initialImageUrl={avatarUrl}
                  onImageUpload={(url) => setAvatarUrl(url)}
              />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-c alignC gap-16">
                {isEditMode && (
                    <Button
                        className="btn-danger"
                        onClick={() => handleDelete(form.getFieldValue("id"))}
                        danger
                        icon={<DeleteOutlined />}
                    >
                      {t('Delete')}
                    </Button>
                )}
                <Button type="primary" className="btn_done" htmlType="submit">
                  {isEditMode ? t("Save") : t("Add")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  );
});

export default GamesBar;
