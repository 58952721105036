import { makeAutoObservable, runInAction } from "mobx";
import {
    createNews,
    editNews,
    deleteNews,
    fetchNews,
    searchNews
} from "../api";

class NewsStore {
    newsList = [];
    loading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(value) {
        this.loading = value;
    }

    setError(value) {
        this.error = value;
    }

    setNewsList(newsList) {
        this.newsList = newsList;
    }

    async createNews(newsData) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await createNews(newsData);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.newsList.push(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to create news');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async editNews(data, id) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await editNews(data, id);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    const index = this.newsList.findIndex(news => news.id === id);
                    if (index !== -1) {
                        this.newsList[index] = response.data;
                    }
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to edit news');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async deleteNews(newsId) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await deleteNews(newsId);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.newsList = this.newsList.filter(news => news.id !== newsId);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to delete news');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchNews() {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await fetchNews();
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setNewsList(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to fetch news');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async searchNews(query) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await searchNews(query);
            if (response.status >= 200 && response.status < 300) {
                runInAction(() => {
                    this.setNewsList(response.data);
                });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to search news');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const newsStore = new NewsStore();
export default newsStore;
