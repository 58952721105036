import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import reportWebVitals from './reportWebVitals';
import App from './App';
import keycloak from "./keycloak";
import "./i18n"
import "./style/custom-css.scss";
import "./style/index.scss";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ReactKeycloakProvider>
);

reportWebVitals();
