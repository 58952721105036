import React from "react";
import { Card, Button } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import userStore from "../../../store/user";
import "./style.scss";

const ProfileTeamDetails = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirect = () => {
    const path = userStore.profileDetails?.team
      ? `/team/${userStore.profileDetails?.team?.id}`
      : "/team/search";
    navigate(path);
  };

  return (
    <Card className="content team-card">
      {userStore.profileDetails?.team ? (
        <>
          <div className="title">
            <div className="team-name">
              {t("Team")}: {userStore.profileDetails?.team.fullName}
            </div>
          </div>
          <Button
            type="primary"
            className="btn_small btn-center"
            onClick={handleRedirect}
          >
            {t("Go to team")}
          </Button>
        </>
      ) : (
        <div className="title">{t("No team")}</div>
      )}
    </Card>
  );
});

export default ProfileTeamDetails;
