import React, { useState } from "react";
import { Form, Input, Button, Alert, notification } from "antd";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { useTranslation } from 'react-i18next';
import { register, smsCheck, checkName, checkEmail } from "../../../api";
import Logo from "../../../components/logo";
import "./style.scss";

const Register = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const onFinishStep1 = async (values) => {
    console.log("Step 1 Success:", values);

    const usernameCheckResponse = await checkName(values.username);
    console.log("Username check response:", usernameCheckResponse);

    const emailCheckResponse = await checkEmail(values.email);
    console.log("Email check response:", emailCheckResponse);

    if (
        usernameCheckResponse.status === 200 &&
        emailCheckResponse.status === 200
    ) {
      const phoneWithPrefix = `77${values.phone.replace(/^7/, '')}`;
      const updatedValues = { ...values, phone: phoneWithPrefix };
      setData(updatedValues);
      const smsResponse = await smsCheck(phoneWithPrefix);
      console.log("SMS check response:", smsResponse);
      if (smsResponse.status === 200) {
        setStep(2);
      } else {
        openNotificationWithIcon(
            "error",
            t("Error sending SMS"),
            smsResponse.message || t("Unknown error"),
        );
      }
    } else {
      if (usernameCheckResponse.status !== 200) {
        setIsUsernameAvailable(false);
        setError(t("Username is already taken!"));
      }
      if (emailCheckResponse.status !== 200) {
        setIsEmailAvailable(false);
        setError(t("Email is already taken!"));
      }
    }
  };

  const onFinishStep2 = async (values) => {
    const { confirm, ...formData } = { ...data, smsCode: values.smsCode };
    try {
      const registerResponse = await register(formData);
      console.log("Register response:", registerResponse);
      if (registerResponse) {
        navigate('/');
      } else {
        openNotificationWithIcon(
            "error",
            t("Registration Error"),
            registerResponse.message || t("Error registering user"),
        );
      }
    } catch (error) {
      openNotificationWithIcon(
          "error",
          t("Error"),
          error.message || t("Unknown error"),
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const checkUsername = async (value) => {
    if (!value) {
      return { isValid: true };
    }

    const response = await checkName(value);
    console.log("response", response.data);
    if (response.data) {
      return { isValid: true };
    } else {
      return { isValid: false, message: t("Username is already taken!") };
    }
  };

  const checkEmailAddress = async (value) => {
    if (!value) {
      return { isValid: true };
    }

    const response = await checkEmail(value);
    if (response.data) {
      return { isValid: true };
    } else {
      return { isValid: false, message: t("Email is already taken!") };
    }
  };

  const validateUsernameDebounced = debounce(async (rule, value, callback) => {
    const result = await checkUsername(value);
    if (result.isValid) {
      setIsUsernameAvailable(true);
      callback();
    } else {
      setIsUsernameAvailable(false);
      callback(result.message);
    }
  }, 500);

  const validateEmailDebounced = debounce(async (rule, value, callback) => {
    const result = await checkEmailAddress(value);
    if (result.isValid) {
      setIsEmailAvailable(true);
      callback();
    } else {
      setIsEmailAvailable(false);
      callback(result.message);
    }
  }, 500);

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10,13}$/;
    if (phoneRegex.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(
          new Error(t("Phone number must be between 10 and 13 digits without spaces!")),
      );
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
      <div className="container register">
        <div className="login-box">
          <div className="form-section">
            <Logo />
            {error && <Alert message={error} type="error" showIcon closable />}
            <Form
                form={form}
                name="register"
                layout="vertical"
                onFinish={step === 1 ? onFinishStep1 : onFinishStep2}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
            >
              {step === 1 && (
                  <>
                    <Form.Item
                        label={t("Username")}
                        name="username"
                        rules={[
                          { required: true, message: t("Please input your username!") },
                          { validator: validateUsernameDebounced },
                        ]}
                        hasFeedback
                        validateStatus={!isUsernameAvailable ? "error" : ""}
                        help={!isUsernameAvailable && t("Username is already taken!")}
                        normalize={(value) => value.toLowerCase()}
                    >
                      <Input placeholder={t("Username")} autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        label={t("Email")}
                        name="email"
                        rules={[
                          { required: true, message: t("Please input your email!") },
                          { type: "email", message: t("The input is not valid E-mail!") },
                          { validator: validateEmailDebounced },
                        ]}
                        hasFeedback
                        validateStatus={!isEmailAvailable ? "error" : ""}
                        help={!isEmailAvailable && t("Email is already taken!")}
                        normalize={(value) => value.toLowerCase()}
                    >
                      <Input placeholder={t("Email")} autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        label={t("Password")}
                        name="password"
                        rules={[
                          { required: true, message: t("Please input your password!") },
                          { min: 8, message: t("Password must be at least 8 characters!") },
                        ]}
                        hasFeedback
                    >
                      <Input.Password
                          placeholder={t("Password")}
                          autoComplete="new-password"
                      />
                    </Form.Item>

                    <Form.Item
                        label={t("Confirm Password")}
                        name="confirm"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          { required: true, message: t("Please confirm your password!") },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                  new Error(t("The two passwords do not match!"))
                              );
                            },
                          }),
                        ]}
                    >
                      <Input.Password
                          placeholder={t("Confirm Password")}
                          autoComplete="new-password"
                      />
                    </Form.Item>


                    <Form.Item
                        label={t("Phone Number")}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t("Please input your phone number!"),
                          },
                          { validator: validatePhoneNumber },
                        ]}
                    >
                      <Input
                          type="number"
                          placeholder=""
                          autoComplete="off"
                          addonBefore="+7"
                      />
                    </Form.Item>
                  </>
              )}

              {step === 2 && (
                  <>
                    <Form.Item
                        label={t("SMS Code")}
                        name="smsCode"
                        rules={[
                          { required: true, message: t("Please input the SMS code!") },
                        ]}
                    >
                      <Input placeholder={t("SMS Code")} autoComplete="off" />
                    </Form.Item>
                  </>
              )}

              <Form.Item>
                <Button
                    className="btn_done"
                    type="primary"
                    htmlType="submit"
                    block
                >
                  {step === 1 ? t("Continue") : t("Register")}
                </Button>
              </Form.Item>
            </Form>

            {/*<Button type="default" onClick={handleLogin} className="btn" block>*/}
            {/*  {t("Login")}*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>
  );
};

export default Register;

