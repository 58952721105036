import axios from "axios";
import { BASE_API_URL } from "../helpers/api";

let isRefreshing = false;
const refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers.length = 0;
}

function addRefreshSubscriber(cb) {
  refreshSubscribers.push(cb);
}

export async function refreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");

  if (isRefreshing) {
    return new Promise((resolve) => {
      addRefreshSubscriber((token) => {
        resolve(token);
      });
    });
  }

  isRefreshing = true;
  try {
    const response = await axios.post(`${BASE_API_URL}/refresh-token`, {
      refreshToken: refreshToken,
    });
    const { access_token, refresh_token } = response.data;
    localStorage.setItem("authToken", access_token);
    localStorage.setItem("refreshToken", refresh_token);
    isRefreshing = false;
    onRefreshed(access_token);
    return access_token;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    isRefreshing = false;
    localStorage.clear();
    window.location.href = "/";
    return null;
  }
}

async function makeRequest(config) {
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
}

export const request = async (url, method, payload, formData, params) => {
  const token = localStorage.getItem("authToken");
  const config = {
    url: `${BASE_API_URL}${url}`,
    headers: {
      ...(formData ? { "Content-Type": "multipart/form-data" } : {}),
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    method,
    data: payload,
    params,
  };

  try {
    const response = await makeRequest(config);
    return { status: response.status, data: response.data };
  } catch (error) {
    const isAuthRequest = url.includes("/login") || url.includes("/register");
    if (error.response?.status === 401 && !config.retryFlag && !isAuthRequest) {
      const newToken = await refreshToken();
      if (newToken) {
        config.headers.Authorization = `Bearer ${newToken}`;
        config.retryFlag = true;
        const response = await makeRequest(config);
        return { status: response.status, data: response.data };
      }
    } else {
      return {
        status: error.response?.status || 500,
        data: null,
        message: error.response?.data?.message || "Unknown error occurred",
      };
    }
  }
};

export async function register(data) {
  return request("/open-api/user/register", "POST", data);
}

export async function login(credentials) {
  return request("/login", "POST", credentials);
}

export async function checkName(username) {
  return request("/open-api/user/check-username", "GET", null, null, {
    username,
  });
}

export async function checkEmail(email) {
  return request("/open-api/user/check-email", "GET", null, null, { email });
}

export async function smsCheck(phoneNumber) {
  return request("/open-api/sms/send", "GET", null, null, { phoneNumber });
}

// USER
export async function getUser() {
  return request("/api/user/current-profile", "GET");
}

export async function getUserProfile(username) {
  console.log('API getUserProfile called with username:', username);
  return request("/api/user/profile", "GET", null, null, { username });
}

export async function checkCapitan() {
  return request("/api/user/check-captain", "GET");
}

export async function editUser(data) {
  return request("/api/user/edit", "PUT", data);
}

// TEAM
export async function getTeam(teamId) {
  return request("/api/team/profile", "GET", null, null, { teamId });
}

export async function createTeam(data) {
  return request("/api/team/register", "POST", data);
}

export async function addTeam(data) {
  return request("/api/team-player/add", "POST", data);
}

export async function deleteTeamMember(data) {
  return request("/api/team-player/remove", "DELETE", data);
}

export async function editTeam(data, teamId) {
  return request("/api/team/edit", "PUT", data, null, { teamId });
}

export async function search(search) {
  return request("/api/user/search", "GET", null, null, { search });
}

export async function searchTeam(search) {
  return request("/api/team/search", "GET", null, null, { search });
}
// TOURNAMENT
export async function searchTournaments(keyword) {
  return request("/api/tournament/search", "GET", null, null, { keyword });
}
export async function createTournaments(data) {
  return request("/api/tournament/create", "POST", data);
}
export async function addPlayerTournament(data) {
  return request("/api/tournament/add-participant", "POST", data);
}
export async function getTournament(id) {
  return request("/api/tournament/profile", "GET", null, null, { id });
}

export async function editTournament(data, tournamentId) {
  return request("/api/tournament/edit", "PUT", data, null, { tournamentId });
}

export async function deletePlayerTournament(username) {
  return request("/api/tournament/delete-participant", "DELETE", null, null, {
    username,
  });
}

export async function checkTournament(id) {
  return request("/api/tournament/register-status", "GET", null, null, id);
}

// Games

export async function addDiscipline(id) {
  return request("/api/discipline/add", "GET", null, null, { id });
}

export async function editDict(data, id) {
  return request("/api/discipline/edit", "PUT", data, null, { id });
}

export async function createDict(data) {
  return request("/api/discipline/add", "POST", data);
}

export async function getDict() {
  return request("/api/discipline/findAll", "GET");
}
export async function deleteDict(id) {
  return request("/api/discipline/remove", "DELETE", null, null, { id });
}

// Avatar
export async function uploadFile(data) {
  return request("/api/file/upload", "POST", data, true);
}

// NEWS

export async function editNews(data, id) {
  return request("/api/news/update", "PUT", data, null, { id });
}

export async function createNews(data) {
  return request("/api/news/create", "POST", data);
}

export async function searchNews(id) {
  return request("/open-api/news/find", "GET", null, null, { id });
}

export async function fetchNews() {
  return request("/open-api/news/all", "GET");
}

export async function deleteNews(id) {
  return request("/api/news/delete", "DELETE", null, null, { id });
}
