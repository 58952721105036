import React, { useEffect, useState } from "react";
import { Card, Skeleton, Button, Form, Input, message } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import teamStore from "../../../store/team";
import CustomAvatar from "../../../components/avatar";

const NoTeam = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleCreateTeam = async (values) => {
    try {
      await teamStore.createTeam({
        fullName: values.fullName,
        logo: imageUrl.url,
        estDate: dayjs().format("YYYY-MM-DD"),
      });
      message.success(t("Team created successfully!"));
      navigate("/team");
    } catch (error) {
      message.error(t("Failed to create team!"));
    }
  };

  const handleImageUpload = (url) => {
    setImageUrl(url);
  };

  return (
      <div className="container team">
        <Card style={{ margin: 20 }} className="content">
          {loading ? (
              <Skeleton active />
          ) : (
              <div className="flower">
                <div className="neck">
                  <Form
                      form={form}
                      onFinish={handleCreateTeam}
                      layout="vertical"
                      requiredMark={false}
                  >
                    <Form.Item
                        name="fullName"
                        label={t("Team Name")}
                        rules={[{ required: true, message: t("Please input the team name!") }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Logo")}
                        rules={[{ required: true, message: t("Please upload a logo!") }]}
                    >
                      <CustomAvatar initialImageUrl={imageUrl} onImageUpload={handleImageUpload} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" className="btn_done" htmlType="submit" block>
                        {t("Create Team")}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
          )}
        </Card>
      </div>
  );
};

export default NoTeam;
