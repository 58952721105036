import React from 'react';
import { Card, Button } from 'antd';
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import userStore from "../../../store/user";
import './style.scss';

const ProfileTeam = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleRedirect = () => {
        const path = userStore.user?.team ? '/team' : '/team/create';
        navigate(path);
    };

    return (
        <Card className="content team-card">
            <div className="title">
                {userStore.user?.team ? t('Your team') : t('Create a team')}
            </div>
            {userStore.user?.team && (
                <div className="title">
                    <div className="team-name">{t('Team')}: {userStore.user.team.fullName}</div>
                </div>
            )}
            <Button type="primary" className="btn_small btn-center" onClick={handleRedirect}>
                {userStore.user?.team ? t('Go to team') : t('Create a team')}
            </Button>
        </Card>
    );
});

export default ProfileTeam;
