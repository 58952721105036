import React from "react";
import { Card } from "antd";
import "./style.scss";
import HaveTeam from "./haveteam";
import NoTeam from "./noteam";

const Team = () => {

  return (
      <div className="container team">
        <Card style={{ margin: 20 }} className="content">
          <HaveTeam />
        </Card>
      </div>
  );
};

export default Team;
