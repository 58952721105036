import {makeAutoObservable, runInAction} from 'mobx';
import { uploadFile } from "../api";

class ImageStore {
    imageUrl = '';
    loading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(value) {
        this.loading = value;
    }

    setError(value) {
        this.error = value;
    }
    setImageUrl(url) {
        this.imageUrl = url;
    }


    async createImg(data) {
        this.setLoading(true);
        try {
            const response = await uploadFile(data);
            runInAction(() => {
                this.setImageUrl(response.data);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const imageStore = new ImageStore();
export default imageStore;
