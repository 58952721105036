import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form, Input, Select, Card, Skeleton, Avatar } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import userStore from "../../../store/user";
import './style.scss';

const { Option } = Select;

const OtherUserProfileCard = observer(() => {
    const { t } = useTranslation();
    const { username } = useParams();
    const [form] = Form.useForm();
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (!userStore.isLoading && (!userStore.profileDetails || userStore.profileDetails.user.username !== username)) {
            userStore.fetchUserByUsername(username);
        }
    }, [username]);

    useEffect(() => {
        if (userStore.profileDetails && initialLoad) {
            const user = userStore.profileDetails.user;
            const birthDate = user?.birthDate ? dayjs(user.birthDate) : null;
            form.setFieldsValue({
                ...user,
                birthDay: birthDate ? birthDate.date() : undefined,
                birthMonth: birthDate ? birthDate.month() + 1 : undefined,
                birthYear: birthDate ? birthDate.year() : undefined
            });
            setInitialLoad(false);
        }
    }, [userStore.profileDetails, form, initialLoad]);

    const currentYear = dayjs().year();
    const years = Array.from({ length: currentYear - 1969 }, (_, i) => currentYear - i);

    if (userStore.isLoading) {
        return (
            <Card bordered={false}>
                <Skeleton active paragraph={{ rows: 4 }} />
            </Card>
        );
    }

    if (userStore.error) {
        return (
            <Card title={t("Error")}>
                <p>{userStore.error}</p>
            </Card>
        );
    }

    const user = userStore.profileDetails?.user;

    return (
        <Card className="content">
            <div className="flex justify-c">
                <Avatar size={128} src={user?.avatarUrl} alt="avatar" />
            </div>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    ...user,
                    birthDay: user?.birthDate ? dayjs(user.birthDate).date() : undefined,
                    birthMonth: user?.birthDate ? dayjs(user.birthDate).month() + 1 : undefined,
                    birthYear: user?.birthDate ? dayjs(user.birthDate).year() : undefined
                }}
                className="form-container"
            >
                <Form.Item label={t("Username")} name="username">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("First Name")} name="firstName">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("Last Name")} name="lastName">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("Middle Name")} name="middleName">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("Email")} name="email">
                    <Input disabled />
                </Form.Item>
                <Form.Item label={t("Phone")} name="phone">
                    <Input disabled addonBefore="+7" />
                </Form.Item>
                <Form.Item label={t("Birth Date")}>
                    <Input.Group compact>
                        <Form.Item name="birthDay" noStyle>
                            <Select placeholder={t("Day")} disabled style={{ width: '30%' }}>
                                {Array.from({ length: 31 }, (_, i) => (
                                    <Option key={i + 1} value={i + 1}>{(i + 1).toString().padStart(2, '0')}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="birthMonth" noStyle>
                            <Select placeholder={t("Month")} disabled style={{ width: '30%' }}>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <Option key={i + 1} value={i + 1}>{dayjs().month(i).format('MM')}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="birthYear" noStyle>
                            <Select placeholder={t("Year")} disabled style={{ width: '40%' }}>
                                {years.map(year => (
                                    <Option key={year} value={year}>{year}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item label={t("Telegram Username")} name="tgUsername">
                    <Input disabled />
                </Form.Item>
            </Form>
        </Card>
    );
});

export default OtherUserProfileCard;
