import { useMemo } from "react";
import { observer } from "mobx-react";
import userStore from "../../store/user";
import teamStore from "../../store/team";


const useUserPermissions = () => {
    const isAdmin = userStore.user?.user?.isAdmin;
    const isCaptain = teamStore.team?.teamInfo?.captainUsername === userStore.user?.user?.username;
    const isAdminOrCaptain = useMemo(() => isAdmin || isCaptain, [isAdmin, isCaptain]);

    return { isAdmin, isCaptain, isAdminOrCaptain };
};

export default useUserPermissions;
