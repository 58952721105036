import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Col,
  Row,
  Skeleton,
  message,
  Modal,
  Form,
  Input,
  Dropdown,
  Menu,
  Table,
} from "antd";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  PlusOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import teamStore from "../../../store/team";
import ModalConfirm from "../../../components/modals/modal-confirm";
import SearchUserModal from "../../../components/modals/modal-search";
import CustomAvatar from "../../../components/avatar";
import useUserPermissions from "../../../helpers/hooks";
import "./style.scss";

const HaveTeam = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { isAdminOrCaptain } = useUserPermissions();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        await teamStore.fetchTeamById();
      } catch (error) {
        console.error("Error fetching team data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTeamData();
  }, []);

  const addNewPlayer = () => {
    if (isAdminOrCaptain) {
      setIsSearchModalVisible(true);
    } else {
      message.error(t("Only admins or captains can add new players"));
    }
  };

  const confirmDeletePlayer = (player) => {
    if (isAdminOrCaptain) {
      setSelectedPlayer(player);
      setIsDeleteModalVisible(true);
    } else {
      message.error(t("Only admins or captains can delete players"));
    }
  };

  const handleDeleteOk = async () => {
    if (selectedPlayer) {
      const data = {
        teamId: teamStore.team.teamInfo.id,
        username: selectedPlayer.username,
      };
      try {
        await teamStore.delete(data);
        message.success(t("Player deleted successfully"));
        await teamStore.fetchTeamById(teamStore.team.teamInfo.id);
      } catch (error) {
        message.error(t("Failed to delete player"));
        console.error("Error deleting player:", error);
      } finally {
        setIsDeleteModalVisible(false);
        setSelectedPlayer(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedPlayer(null);
  };

  const handleEditTeam = () => {
    if (isAdminOrCaptain) {
      if (teamStore.team && teamStore.team.teamInfo) {
        form.setFieldsValue({
          fullName: teamStore.team.teamInfo.fullName || "",
          logo: teamStore.team.teamInfo.logo || "",
          estDate: teamStore.team.teamInfo.estDate
            ? dayjs(teamStore.team.teamInfo.estDate)
            : null,
        });
        setIsEditModalVisible(true);
      } else {
        message.error(t("Failed to load team info for editing"));
      }
    } else {
      message.error(t("Only admins or captains can edit team info"));
    }
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedTeam = {
        fullName: values.fullName,
        logo: values.logo.url || teamStore.team.teamInfo.logo,
        estDate: values.estDate
          ? values.estDate.format("YYYY-MM-DD")
          : teamStore.team.teamInfo.estDate,
      };
      await teamStore.editTeam(updatedTeam);
      message.success(t("Team updated successfully"));
      await teamStore.fetchTeamById();
    } catch (error) {
      message.error(t("Failed to update team"));
      console.error("Error updating team:", error);
    } finally {
      setIsEditModalVisible(false);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleViewDetails = (username) => {
    navigate(`/profile/${username}`);
  };

  const playerMenu = (player) => (
    <Menu>
      <Menu.Item
        key="details"
        onClick={() => handleViewDetails(player.username)}
      >
        {t("Details")}
      </Menu.Item>
      {isAdminOrCaptain && (
        <Menu.Item key="delete" onClick={() => confirmDeletePlayer(player)}>
          {t("Delete")}
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: t("Username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("Full Name"),
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) =>
        `${record.userFirstName} ${record.userMiddleName} ${record.userLastName}`,
    },
    {
      title: t("Joined Date"),
      dataIndex: "joinedDate",
      key: "joinedDate",
      render: (date) => dayjs(date).format("D MMMM YYYY"),
    },
    {
      title: t("Exit Date"),
      dataIndex: "exitDate",
      key: "exitDate",
      render: (date) => dayjs(date).format("D MMMM YYYY"),
    },
  ];

  return (
    <div className="team-info">
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="neck">
            <Avatar
              alt="Team Logo"
              size={64}
              src={teamStore.team?.teamInfo?.logoUrl}
            />
            <div className="name size-24">
              {teamStore.team?.teamInfo?.fullName}
              {isAdminOrCaptain && (
                <EditOutlined
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  onClick={handleEditTeam}
                />
              )}
            </div>
          </div>
          <div className="neck">
            <p className="text">
              <CalendarOutlined /> {t("Established Date")}:{" "}
              {dayjs(teamStore.team?.teamInfo?.estDate).format("D MMMM YYYY")}
            </p>
          </div>
          <div className="name">{t("Active Players")}</div>
          <Row gutter={[16, 16]}>
            {isAdminOrCaptain && (
              <Col span={8}>
                <Card
                  className="card card-add"
                  bordered={false}
                  onClick={addNewPlayer}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <PlusOutlined
                    className="title"
                    style={{ fontSize: "32px" }}
                  />
                </Card>
              </Col>
            )}
            {teamStore.team?.activePlayers?.map((player, index) => {
              const firstName = player.userFirstName || "";
              const middleName = player.userMiddleName || "";
              const lastName = player.userLastName || "";
              const playerName =
                `${firstName} ${middleName} ${lastName}`.trim();
              const displayName = playerName
                ? playerName
                : `${t("Teammate")} ${index + 1}`;
              return (
                <Col span={8} key={player.userId || index}>
                  <Card
                    title={<div className="title">{player.username}</div>}
                    bordered={false}
                    className="card"
                  >
                    <div className="stone">
                      <div className="text">{displayName}</div>
                      <div className="text">
                        <CalendarOutlined />{" "}
                        {dayjs(player.joinedDate).format("D MMMM YYYY")}
                      </div>
                      <Dropdown
                        overlay={playerMenu(player)}
                        trigger={["click"]}
                      >
                        <EllipsisOutlined
                          className="text"
                          style={{ cursor: "pointer" }}
                        />
                      </Dropdown>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <div className="name">{t("Ex Players")}</div>
          <Table
              columns={columns}
              dataSource={teamStore.team?.exPlayers || []}
              rowKey={(record) => `${record.username}-${record.joinedDate}`}
              pagination={{ pageSize: 10 }}
          />
        </>
      )}

      <ModalConfirm
        visible={isDeleteModalVisible}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
        title={t("Confirm Deletion")}
        content={t("Are you sure you want to delete this player?")}
      />

      <SearchUserModal
        visible={isSearchModalVisible}
        onCancel={() => setIsSearchModalVisible(false)}
      />

      <Modal
        open={isEditModalVisible}
        title={t("Edit Team Information")}
        onCancel={handleEditCancel}
        onOk={handleEditOk}
      >
        <Form form={form} layout="vertical" requiredMark={false}>
          <Form.Item
            label={t("Full Name")}
            name="fullName"
            rules={[
              { required: true, message: t("Please input the full name!") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("Logo")}
            name="logo"
            rules={[
              { required: true, message: t("Please input the logo URL!") },
            ]}
          >
            <CustomAvatar
              initialImageUrl={teamStore.team?.teamInfo?.logoUrl}
              onImageUpload={(newImageUrl) =>
                form.setFieldsValue({ logo: newImageUrl })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default HaveTeam;
