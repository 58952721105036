import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal, Form, Input, Avatar, message, Dropdown, Menu, Card } from "antd";
import { EllipsisOutlined } from '@ant-design/icons';
import { observer } from "mobx-react";
import { debounce } from "lodash";
import { useTranslation } from 'react-i18next';
import CustomAvatar from "../avatar";
import newsStore from "../../store/news";
import useUserPermissions from "../../helpers/hooks";
import "./style.scss";

const NewsFeed = observer(() => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingNews, setEditingNews] = useState(null);
    const [form] = Form.useForm();
    const [logo, setLogo] = useState(null); // logo to send to backend
    const [logoUrl, setLogoUrl] = useState(null); // logoUrl to display the image

    const { isAdmin } = useUserPermissions();

    useEffect(() => {
        newsStore.fetchNews();
    }, []);

    const showModal = (news) => {
        setEditingNews(news);
        setLogo(news ? news.logo : null);
        setLogoUrl(news ? news.logoUrl : null);
        setIsModalVisible(true);
        if (news) {
            form.setFieldsValue(news);
        } else {
            form.resetFields();
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditingNews(null);
        form.resetFields();
    };

    const handleSubmit = async (values) => {
        const newsData = { ...values, logo };
        try {
            if (editingNews) {
                await newsStore.editNews(newsData, editingNews.id);
                message.success(t("News successfully updated"));
            } else {
                await newsStore.createNews(newsData);
                message.success(t("News successfully created"));
            }
            newsStore.fetchNews();
            handleCancel();
        } catch (error) {
            message.error(t("An error occurred"));
        }
    };

    const handleDelete = async (newsId) => {
        try {
            await newsStore.deleteNews(newsId);
            message.success(t("News successfully deleted"));
            newsStore.fetchNews();
        } catch (error) {
            message.error(t("An error occurred while deleting"));
        }
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            newsStore.searchNews(value);
        }, 300),
        []
    );

    const handleSearch = (e) => {
        debouncedSearch(e.target.value);
    };

    const renderMenu = (item) => (
        <Menu>
            <Menu.Item key="edit" onClick={() => showModal(item)}>
                {t("Edit")}
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(item.id)}>
                {t("Delete")}
            </Menu.Item>
        </Menu>
    );

    const CollapsibleDescription = ({ text }) => {
        const [expanded, setExpanded] = useState(false);

        const toggleExpanded = () => {
            setExpanded(!expanded);
        };

        const truncatedText = text.split("\n").slice(0, 3).join("\n");
        const isTruncated = text.split("\n").length > 3;

        return (
            <div className="text" style={{ whiteSpace: "pre-wrap" }}>
                {expanded ? text : truncatedText}
                {isTruncated && (
                    <span className="expand" onClick={toggleExpanded} style={ {cursor: "pointer" }}>
                        {expanded ? ` ${t("Show less")}` : ` ...${t("Read more")}`}
                    </span>
                )}
            </div>
        );
    };

    return (
        <div className="news">
            <div className="flex gap-10 alignS">
                <Input
                    placeholder={t("Search news")}
                    onChange={handleSearch}
                    style={{ width: 300 }}
                />
                {isAdmin && (
                    <Button className="btn_small" onClick={() => showModal(null)}>
                        {t("Add news")}
                    </Button>
                )}
            </div>
            <div className="news-list">
                {newsStore.newsList.map((item) => (
                    <div key={item.id} className="news-item">
                        <Card className="card-news">
                            <div className="meta">
                                <div className="flower">
                                    <div className="title">{item.title}</div>
                                    {isAdmin && (
                                        <Dropdown overlay={renderMenu(item)} trigger={['click']}>
                                            <EllipsisOutlined className="title" />
                                        </Dropdown>
                                    )}
                                </div>
                                <CollapsibleDescription text={item.info} />
                                {item?.logoUrl && (
                                    <div className="image-card">
                                        <Avatar shape="square" size={128} src={item.logoUrl} alt="news" />
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
            <Modal
                title={editingNews ? t("Edit news") : t("Add news")}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={() => form.submit()}
            >
                <Form form={form} requiredMark={false} layout={"vertical"} onFinish={handleSubmit}>
                    <Form.Item
                        name="title"
                        label={t("Title")}
                        rules={[
                            { required: true, message: t("Please enter the title") },
                            { max: 110, message: t("Title cannot be longer than 110 characters") }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="info"
                        label={t("Information")}
                        rules={[{ required: true, message: t("Please enter the information") }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        name="logo"
                        label={t("Logo")}
                    >
                        <CustomAvatar
                            shape="square"
                            initialImageUrl={logoUrl}
                            onImageUpload={(url) => {
                                setLogo(url.url); // set logo for backend
                                setLogoUrl(url); // set logoUrl for display
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
});

export default NewsFeed;
