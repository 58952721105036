import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Main from "../pages/main";
import Profile from "../pages/profile";
import Team from "../pages/team";
import NoTeam from "../pages/team/noteam";
import Tournament from "../pages/tournament";
import TournamentDetails from "../pages/tournament-details";
import ProfileDetails from "../pages/profile-details";
import HaveTeamDetails from "../components/team-details";

export default {
  Login: {
    path: "/login",
    element: <Login />,
    protected: false,
    role: "all",
  },
  Register: {
    path: "/register",
    element: <Register />,
    protected: false,
    role: "all",
  },
  Main: {
    path: "/",
    element: <Main />,
    protected: true,
    role: "all",
  },

  Profile: {
    path: "/profile",
    element: <Profile />,
    protected: true,
    role: "all",
  },

  ProfileDetails: {
    path: "/profile/:username",
    element: <ProfileDetails />,
    protected: true,
    role: "all",
  },

  Team: {
    path: "/team",
    element: <Team />,
    protected: true,
    role: "all",
  },

  TeamDetails: {
    path: "/team/:id",
    element: <HaveTeamDetails />,
    protected: true,
    role: "all",
  },

  TeamSearch: {
    path: "/team/create",
    element: <NoTeam />,
    protected: true,
    role: "all",
  },

  Tournament: {
    path: "/tournaments",
    element: <Tournament />,
    protected: true,
    role: "all",
  },

  TournamentDetails: {
    path: "/tournaments/detail/:id",
    element: <TournamentDetails />,
    protected: true,
    role: "all",
  },
};
