import React from 'react';
import { Card, List } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import userStore from "../../../store/user";
import dayjs from "dayjs";
import './style.scss';

const ProfileTournament = observer(() => {
    const { t } = useTranslation();
    const user = userStore.user;

    if (!user || !user.pastTournaments || user.pastTournaments.length === 0) {
        return (
            <Card className="content tournament-card">
                <h2 className="name">{t('Tournaments')}</h2>
                <p className="text">{t('No past tournaments.')}</p>
            </Card>
        );
    }


    const formatDateRange = (startDate, endDate) => {
        const start = dayjs(startDate).format('D MMMM');
        const end = dayjs(endDate).format('D MMMM');
        return `${start} - ${end}`;
    };

    const tournaments = user.pastTournaments.map(tournament => ({
        name: tournament.tournamentFullName,
        date: tournament.startDate,
        result: `${formatDateRange(tournament.startDate, tournament.endDate)} | ${tournament.disciplineName}`
    }));

    return (
        <Card className="content tournament-card">
            <h2 className="name">{t('Tournaments')}</h2>
            <List
                itemLayout="horizontal"
                dataSource={tournaments}
                renderItem={tournament => (
                    <List.Item>
                        <List.Item.Meta
                            title={<span className="title">{tournament.name}</span>}
                            description={<span className="text">{`${tournament.result}`}</span>}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
});

export default ProfileTournament;
