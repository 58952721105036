import { makeAutoObservable, runInAction } from "mobx";
import {createDict, deleteDict, editDict, getDict} from "../api"; // замените на реальные пути к вашим API функциям

class DisciplineStore {
    disciplines = [];
    loading = false;
    error = null;
    currentDiscipline = "all";

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(value) {
        this.loading = value;
    }

    setError(value) {
        this.error = value;
    }

    setDisciplines(disciplines) {
        this.disciplines = disciplines;
    }

    setCurrentDiscipline(disciplineId) {
        this.currentDiscipline = disciplineId;
    }

    async createDiscipline(data) {
        this.setLoading(true);
        try {
            const response = await createDict(data);
            runInAction(() => {
                this.setDisciplines([...this.disciplines, response.data]);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async editDiscipline(data, id) {
        this.setLoading(true);
        try {
            const response = await editDict(data, id);
            runInAction(() => {
                this.setDisciplines(
                    this.disciplines.map(discipline =>
                        discipline.id === data.id ? response.data : discipline
                    )
                );
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    async fetchDisciplines() {
        this.setLoading(true);
        try {
            const response = await getDict();
            runInAction(() => {
                this.setDisciplines(response.data);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }


    async deleteDisciplines(id) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await deleteDict(id);
            if (response.status >= 200 && response.status < 300) {
                // runInAction(() => {
                //     this.newsList = this.newsList.filter(news => news.id !== id);
                // });
                return response.data;
            } else {
                throw new Error(response.message || 'Failed to delete news');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const disciplineStore = new DisciplineStore();
export default disciplineStore;
