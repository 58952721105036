import React from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import "./style.scss";

const ModalConfirm = ({ visible, onConfirm, onCancel, title, content }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={visible}
            className="modal-confirm"
            title={title}
            onCancel={onCancel}
            footer={[]}
        >
            <div className="text">{content}</div>
            <div className="war">
                <Button className="btn-cancel" onClick={onCancel}>
                    {t("Cancel")}
                </Button>
                <Button className="btn_small" onClick={onConfirm}>
                    {t("Confirm")}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalConfirm;
