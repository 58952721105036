import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal, Form, Input, Select, Button, notification } from "antd";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import tournamentStore from "../../../store/tournaments";
import disciplineStore from "../../../store/games";
import CustomAvatar from "../../avatar";
import "./style.scss";
import { useParams } from "react-router-dom";

const { Option } = Select;

const CreateTournamentModal = observer(({ visible, onCancel, tournament }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { id } = useParams();

    useEffect(() => {
        if (tournament) {
            form.setFieldsValue({
                fullName: tournament.fullName,
                description: tournament.description,
                disciplineId: tournament.disciplineId,
                format: tournament.format,
                avatar: { url: tournament.logo?.url },
                startDay: dayjs(tournament.startDate).date(),
                startMonth: dayjs(tournament.startDate).month() + 1,
                endDay: dayjs(tournament.endDate).date(),
                endMonth: dayjs(tournament.endDate).month() + 1,
                capacity: tournament.capacity,
            });
        } else {
            form.resetFields();
        }
    }, [tournament, form]);

    const onFinish = async (values) => {
        const currentYear = dayjs().year();
        const startDate = `${currentYear}-${values.startMonth?.toString().padStart(2, '0')}-${values.startDay?.toString().padStart(2, '0')}`;
        const endDate = `${currentYear}-${values.endMonth?.toString().padStart(2, '0')}-${values.endDay?.toString().padStart(2, '0')}`;
        const newTournament = {
            fullName: values.fullName,
            capacity: parseInt(values.capacity, 10),
            format: values.format,
            disciplineId: values.disciplineId,
            startDate: startDate,
            endDate: endDate,
            logo: values.avatar.url,
            description: values.description.replace(/\n/g, "\r\n"), // Replace \n with \r\n
        };

        try {
            if (tournament) {
                await tournamentStore.editTournament(newTournament, tournament.id);
                notification.success({
                    message: t('Tournament Updated'),
                    description: t('The tournament has been updated successfully.'),
                });
            } else {
                await tournamentStore.fetchCreateTournament(newTournament);
                notification.success({
                    message: t('Tournament Created'),
                    description: t('The tournament has been created successfully.'),
                });
            }
            tournamentStore.getTournamentProfile(id);
            form.resetFields();
            onCancel();
        } catch (error) {
            console.error("Failed to save tournament:", error);
            notification.error({
                message: t('Save Failed'),
                description: t('There was an error saving the tournament. Please try again later.'),
            });
        }
    };

    const currentYear = dayjs().year();
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);

    return (
        <Modal
            title={tournament ? t("Edit Tournament") : t("Create Tournament")}
            open={visible}
            className="modal-create-tournament"
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
                <Form.Item
                    label={t("Tournament Name")}
                    name="fullName"
                    rules={[{ required: true, message: t("Please enter tournament name") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("Description")}
                    name="description"
                    rules={[{ required: true, message: t("Please enter description") }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    label={t("Discipline")}
                    name="disciplineId"
                    rules={[{ required: true, message: t("Please select a discipline") }]}
                >
                    <Select>
                        {disciplineStore.disciplines.map((discipline) => (
                            <Option key={discipline.id} value={discipline.id}>
                                {discipline.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t("Format")}
                    name="format"
                    rules={[{ required: true, message: t("Please select a format") }]}
                >
                    <Select placeholder={t("Select format")}>
                        <Option value="1x1">1x1</Option>
                        <Option value="3x3">3x3</Option>
                        <Option value="5x5">5x5</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t("Avatar")}
                    name="avatar"
                    rules={[{ required: true, message: t("Please upload an avatar") }]}
                >
                    <CustomAvatar
                        shape="square"
                        initialImageUrl={tournament ? tournament.logoUrl : null}
                        onImageUpload={(newImageUrl) => form.setFieldsValue({ avatar: newImageUrl })}
                    />
                </Form.Item>
                <Form.Item label={t("Start Date")}>
                    <Input.Group compact>
                        <Form.Item name="startDay" noStyle rules={[{ required: true, message: t("Please select start day") }]}>
                            <Select placeholder={t("Day")} style={{ width: '40%' }}>
                                {days.map((day) => (
                                    <Option key={day} value={day}>
                                        {day.toString().padStart(2, '0')}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="startMonth" noStyle rules={[{ required: true, message: t("Please select start month") }]}>
                            <Select placeholder={t("Month")} style={{ width: '60%' }}>
                                {months.map((month) => (
                                    <Option key={month} value={month}>
                                        {dayjs().month(month - 1).format('MMMM')}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item label={t("End Date")}>
                    <Input.Group compact>
                        <Form.Item name="endDay" noStyle rules={[{ required: true, message: t("Please select end day") }]}>
                            <Select placeholder={t("Day")} style={{ width: '40%' }}>
                                {days.map((day) => (
                                    <Option key={day} value={day}>
                                        {day.toString().padStart(2, '0')}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="endMonth" noStyle rules={[{ required: true, message: t("Please select end month") }]}>
                            <Select placeholder={t("Month")} style={{ width: '60%' }}>
                                {months.map((month) => (
                                    <Option key={month} value={month}>
                                        {dayjs().month(month - 1).format('MMMM')}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    label={t("Capacity")}
                    name="capacity"
                    rules={[{ required: true, message: t("Please enter capacity") }]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="btn_done btn-center" block htmlType="submit">
                        {tournament ? t("Update") : t("Create")}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default CreateTournamentModal;
