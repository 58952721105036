import { makeAutoObservable, runInAction } from "mobx";
import { login } from "../api";

class AuthStore {
    token = null;
    refreshToken = null;
    loading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
        this.loadToken();
    }

    setLoading(value) {
        this.loading = value;
    }

    setError(value) {
        this.error = value;
    }

    setToken(token) {
        this.token = token;
        localStorage.setItem('authToken', token);
    }

    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
        localStorage.setItem('refreshToken', refreshToken);
    }

    clearToken() {
        this.token = null;
        this.refreshToken = null;
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
    }

    loadToken() {
        const token = localStorage.getItem('authToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (token) {
            this.token = token;
        }
        if (refreshToken) {
            this.refreshToken = refreshToken;
        }
    }

    async fetchLogin(credentials) {
        this.setLoading(true);
        this.setError(null);
        try {
            const response = await login(credentials);
            console.log("response", response)
            if (response) {
                runInAction(() => {
                    this.setToken(response.data.access_token);
                    this.setRefreshToken(response.data.refresh_token);
                });
                return response;
            } else {
                throw new Error(response.message || 'Failed to login');
            }
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
            throw error;
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }
}

const authStore = new AuthStore();
export default authStore;
