import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Col,
  Row,
  Skeleton,
  message,
  Modal,
  Form,
  Input,
  Dropdown,
  Menu,
} from "antd";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  PlusOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import teamStore from "../../store/team";
import ModalConfirm from "../../components/modals/modal-confirm";
import SearchUserModal from "../../components/modals/modal-search";
import CustomAvatar from "../../components/avatar";
import useUserPermissions from "../../helpers/hooks";
import "./style.scss";

const HaveTeamDetails = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { isAdminOrCaptain } = useUserPermissions();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        await teamStore.fetchTeam(id);
      } catch (error) {
        console.error("Error fetching team data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTeamData();
  }, [id]);

  const handleDeleteOk = async () => {
    if (selectedPlayer) {
      const data = {
        teamId: teamStore.teamDetails.teamInfo.id,
        username: selectedPlayer.username,
      };
      try {
        await teamStore.delete(data);
        message.success(t("Player deleted successfully"));
        await teamStore.fetchTeamById(teamStore.teamDetails.teamInfo.id);
      } catch (error) {
        message.error(t("Failed to delete player"));
        console.error("Error deleting player:", error);
      } finally {
        setIsDeleteModalVisible(false);
        setSelectedPlayer(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedPlayer(null);
  };

  const handleEditTeam = () => {
    if (isAdminOrCaptain) {
      if (teamStore.teamDetails && teamStore.teamDetails.teamInfo) {
        form.setFieldsValue({
          fullName: teamStore.teamDetails.teamInfo.fullName || "",
          logo: teamStore.teamDetails.teamInfo.logo || "",
          estDate: teamStore.teamDetails.teamInfo.estDate
            ? dayjs(teamStore.teamDetails.teamInfo.estDate)
            : null,
        });
        setIsEditModalVisible(true);
      } else {
        message.error(t("Failed to load team info for editing"));
      }
    } else {
      message.error(t("Only admins or captains can edit team info"));
    }
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedTeam = {
        fullName: values.fullName,
        logo: values.logo.url || teamStore.teamDetails.teamInfo.logo,
        estDate: values.estDate
          ? values.estDate.format("YYYY-MM-DD")
          : teamStore.teamDetails.teamInfo.estDate,
      };
      await teamStore.editTeam(updatedTeam);
      message.success(t("Team updated successfully"));
      await teamStore.fetchTeamById(id);
    } catch (error) {
      message.error(t("Failed to update team"));
      console.error("Error updating team:", error);
    } finally {
      setIsEditModalVisible(false);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleViewDetails = (username) => {
    navigate(`/profile/${username}`);
  };

  const playerMenu = (player) => (
    <Menu>
      <Menu.Item
        key="details"
        onClick={() => handleViewDetails(player.username)}
      >
        {t("Details")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="container team">
      <Card style={{ margin: 20 }} className="content">
        <div className="team-info">
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="neck">
                <Avatar
                  alt="Team Logo"
                  size={64}
                  src={teamStore.teamDetails?.teamInfo?.logoUrl}
                />
                <div className="name size-24">
                  {teamStore.teamDetails?.teamInfo?.fullName}
                </div>
              </div>
              <div className="neck">
                <p className="text">
                  <CalendarOutlined /> {t("Established Date")}:{" "}
                  {dayjs(teamStore.teamDetails?.teamInfo?.estDate).format(
                    "D MMMM YYYY",
                  )}
                </p>
              </div>
              <div className="name">{t("Active Players")}</div>
              <Row gutter={[16, 16]}>
                {teamStore.teamDetails?.activePlayers?.map((player, index) => {
                  const firstName = player.userFirstName || "";
                  const middleName = player.userMiddleName || "";
                  const lastName = player.userLastName || "";
                  const playerName =
                    `${firstName} ${middleName} ${lastName}`.trim();
                  const displayName = playerName
                    ? playerName
                    : `${t("Teammate")} ${index + 1}`;
                  return (
                    <Col span={8} key={player.userId || index}>
                      <Card
                        title={<div className="title">{player.username}</div>}
                        bordered={false}
                        className="card"
                      >
                        <div className="stone">
                          <div className="text">{displayName}</div>
                          <div className="text">
                            <CalendarOutlined />{" "}
                            {dayjs(player.joinedDate).format("D MMMM YYYY")}
                          </div>
                              <Dropdown overlay={playerMenu(player)} trigger={['click']}>
                                <EllipsisOutlined className="text" style={{ cursor: "pointer" }} />
                              </Dropdown>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </>
          )}

          <ModalConfirm
            visible={isDeleteModalVisible}
            onConfirm={handleDeleteOk}
            onCancel={handleDeleteCancel}
            title={t("Confirm Deletion")}
            content={t("Are you sure you want to delete this player?")}
          />

          <SearchUserModal
            visible={isSearchModalVisible}
            onCancel={() => setIsSearchModalVisible(false)}
          />

          <Modal
            open={isEditModalVisible}
            title={t("Edit Team Information")}
            onCancel={handleEditCancel}
            onOk={handleEditOk}
          >
            <Form form={form} layout="vertical" requiredMark={false}>
              <Form.Item
                label={t("Full Name")}
                name="fullName"
                rules={[
                  { required: true, message: t("Please input the full name!") },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("Logo")}
                name="logo"
                rules={[
                  { required: true, message: t("Please input the logo URL!") },
                ]}
              >
                <CustomAvatar
                  initialImageUrl={teamStore.teamDetails?.teamInfo?.logoUrl}
                  onImageUpload={(newImageUrl) =>
                    form.setFieldsValue({ logo: newImageUrl })
                  }
                />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Card>
    </div>
  );
});

export default HaveTeamDetails;
