import React from 'react';
import Icon from "../icon";

const Logo = ({text}) => {
    return (
        <div className="logo">
            <Icon name="logo_white" />
            {/*{text && <div className="text">UEL</div> }*/}
            {/*{!text && <div className="title">UEL</div>}*/}
        </div>
    );
};

export default Logo;
