import React from 'react';
import { observer } from 'mobx-react';
import ProfileCard from "../../components/profile/profile-card";
import ProfileTeam from "../../components/profile/profile-team";
import ProfileTournament from "../../components/profile/profile-tournament";
import ProfileTournamentUpcoming from "../../components/profile/profile-upcoming";
import './style.scss';

const Profile = observer(() => {
    return (
        <div className="container profile">
            <div className="card-container">
                <ProfileCard />
                <div className="flower">
                    <ProfileTeam />
                    <ProfileTournament />
                    <ProfileTournamentUpcoming />
                </div>
            </div>
        </div>
    );
});

export default Profile;
